<template>
  <div>
    <input
      v-if="dataRecurly"
      v-model="inputText"
      type="hidden"
      :data-recurly="dataRecurly"
    >
    <div
      :ref="refId"
      class="select"
      :class="[{'select_open': isOpen, 'select_disabled': settings.disable}, {'select_icon' : settings.iconSelectClass}, settings.selectClass]"
      @mouseenter="handleClick()"
      @mouseleave="handleLeave()"
      @mousedown="handleClick()"
    >
      <i
        class="select__icon"
        :class="settings.iconSelectClass"
      >
      </i>
      <template v-if="settings.readonly === true">
        <input
          :ref="'selectInput-' + refId"
          v-model="inputText"
          type="text"
          class="select__input"
          :tabindex="settings.tabindex"
          :placeholder="settings.placeholderText"
          readonly
          @blur="checkValue()"
          @focus="isOpen=true"
          @keydown.stop="selectInputKeyDown($event)"
        >
      </template>
      <template v-else>
        <input
          :ref="'selectInput-' + refId"
          v-model="inputText"
          type="text"
          class="select__input"
          :tabindex="settings.tabindex"
          :placeholder="settings.placeholderText"
          @blur="checkValue()"
          @focus="isOpen=true"
          @keydown.stop="selectInputKeyDown($event)"
        >
      </template>

      <VuePerfectScrollbar
        class="select__drop"
        :class="{'select__drop_top': settings.toTop}"
      >
        <div class="select__drop-list">
          <div
            v-for="(option, index) in computedOptionsList"
            :key="index"
          >
            <template v-if="index === 0">
              <a
                :ref="'select__drop-link_first-' + refId"
                href="javascript:void(0)"
                class="select__drop-link"
                :class="[
                  {'select__drop-link_checked': option.key === inputValue},
                  {'select__drop-link_checked': option.routeName === $route.name && settings.activeElement}
                ]"
                @keydown.prevent.stop="selectDropLinkKeyDown($event, option.key)"
                @mousedown.stop="updateSelect(option.key)"
              >{{ option.text }}</a>
            </template>
            <template v-else>
              <a
                href="javascript:void(0)"
                class="select__drop-link"
                :class="[
                  {'select__drop-link_checked': option.key === inputValue},
                  {'select__drop-link_checked': option.routeName === $route.name && settings.activeElement}
                ]
                "
                @keydown.prevent.stop="selectDropLinkKeyDown($event, option.key)"
                @mousedown.stop="updateSelect(option.key)"
              >{{ option.text }}</a>
            </template>
            <i
              class="icon-confirm select__check"
              :class="{'select__check_checked': option.key === inputValue}"
            ></i>
          </div>
        </div>
      <!--<div v-if="computedOptionsList.length === 0">-->
      <!--<a href="javascript:void(0)" class="select__drop-link">Not Founded</a>-->
      <!--</div>-->
      </VuePerfectScrollbar>
    </div>
  </div>
</template>

<script>
// import consoleMixin from '@@/mixins/console'
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import find from 'lodash/find';
import filter from 'lodash/filter';
import includes from 'lodash/includes';

export default {
  name: 'SelectWithKeyTemplate', // 'optionList', 'placeholderText', 'selectedKey', 'selectClass', 'toTop', 'disable', 'tabindex'
  components: {
    VuePerfectScrollbar,
  },
  // mixins: [consoleMixin],
  props: ['settings'],
  data() {
    return {
      dataRecurly: this.settings.dataRecurly,
      isOpen: false,
      value: this.settings.selectedKey,
      refId: Math.floor(new Date().valueOf() * Math.random()),
      searchValue: '',
      inputValueStr: '',
      selectInputKeyDownStatus: false,
      notChange: this.settings.notChange,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(data) {
        if (!this.notChange) {
          this.value = data;
        }
      },
    },
    inputText: {
      get() {
        return this.inputValueStr;
      },
      set(data) {
        this.inputValueStr = data;
        this.searchValue = data;
      },
    },
    computedOptionsList() {
      const owner = this;
      let arr = [];
      if (this.isOpen) {
        try {
          arr = filter(this.settings.optionList, (obj) => includes(obj.text.toLowerCase(), owner.searchValue.toLowerCase()));
        } catch (e) {
          arr = this.settings.optionList;
        }
      } else {
        arr = this.settings.optionList;
      }
      return arr;
    },
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.searchValue = '';
      } else {
        const thisEl = this;
        const el = find(this.computedOptionsList, (obj) => obj.key === thisEl.inputValue);
        if (el === undefined) this.inputText = '';
        else this.inputText = el.text;
      }
    },
    'settings.selectedKey': function (newVal) {
      this.updateSelect(newVal);
    },
    'settings.optionList': function () {
      this.checkValue();
    },
  },
  mounted() {
    document.addEventListener('click', this.closeSelect);
    if (document.getElementsByClassName('dialog--active')[0] !== undefined) {
      document.getElementsByClassName('dialog--active')[0].addEventListener('click', this.closeSelect);
    }
    const thisEl = this;
    const el = find(this.computedOptionsList, (obj) => obj.key === thisEl.inputValue);
    if (el === undefined) this.inputText = '';
    else this.inputText = el.text;
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeSelect);
    if (document.getElementsByClassName('dialog--active')[0] !== undefined) {
      document.getElementsByClassName('dialog--active')[0].removeEventListener('click', this.closeSelect);
    }
  },
  beforeMount() {
    console.log(this.$route.name);
  },
  methods: {
    checkValue() {
      if (!this.isOpen) {
        const thisEl = this;
        const el = find(this.computedOptionsList, (obj) => obj.key === thisEl.inputValue);
        if (el === undefined) this.inputText = '';
        else this.inputText = el.text;
      }
    },
    selectDropLinkKeyDown(e, key) {
      switch (e.keyCode) {
      case 13:
        e.target.classList.remove('select__drop-link_hover');
        this.updateSelect(key);
        this.$refs[`selectInput-${this.refId}`].focus();
        this.isOpen = false;
        break;
      case 27:
        e.target.classList.remove('select__drop-link_hover');
        this.$refs[`selectInput-${this.refId}`].focus();
        this.isOpen = false;
        break;
      case 38:
        if (e.target.parentNode.previousSibling !== null) {
          e.target.classList.remove('select__drop-link_hover');
          e.target.parentNode.previousSibling.firstChild.classList.add('select__drop-link_hover');
          e.target.parentNode.previousSibling.firstChild.focus();
        } else {
          this.$refs[`selectInput-${this.refId}`].focus();
          e.target.parentNode.firstChild.classList.remove('select__drop-link_hover');
        }
        break;
      case 40:
        if (e.target.parentNode.nextSibling !== null) {
          e.target.classList.remove('select__drop-link_hover');
          e.target.parentNode.nextSibling.firstChild.classList.add('select__drop-link_hover');
          e.target.parentNode.nextSibling.firstChild.focus();
        }
        break;
      default:
        this.$refs[`selectInput-${this.refId}`].focus();
        break;
      }
    },
    selectInputKeyDown(e) {
      switch (e.keyCode) {
      case 9:
        this.isOpen = false;
        break;
      case 27:
        e.preventDefault();
        this.isOpen = false;
        break;
      case 38: {
        e.preventDefault();
        break;
      }
      case 40:
        e.preventDefault();
        this.isOpen = true;
        this.$refs[`select__drop-link_first-${this.refId}`][0].focus();
        this.$refs[`select__drop-link_first-${this.refId}`][0].classList.add('select__drop-link_hover');
        break;
      default:
        if (!this.isOpen) this.isOpen = true;
        break;
      }
    },
    handleClick() {
      if (this.settings.hoverState) {
        if (this.settings.disable !== undefined) {
          if (!this.settings.disable) {
            this.isOpen = !this.isOpen;
          }
        } else {
          this.isOpen = !this.isOpen;
        }
      }
    },
    handleLeave() {
      if (this.settings.hoverState) {
        if (this.isOpen) {
          this.isOpen = false;
        } else {
          this.isOpen = true;
        }
      }
      // this.isOpen = !this.isOpen
    },
    closeSelect(e) {
      if (this.$refs[this.refId] !== undefined) {
        if (!this.$refs[this.refId].contains(e.target)) {
          this.isOpen = false;
        }
      }
    },
    updateSelect(key) {
      console.log(this.dataRecurly);
      // let elem = document.querySelector('.select__drop-link_checked')
      const elem = document.querySelector('.select__drop-link').classList.contains('select__drop-link_checked');
      if (elem) {
        this.isOpen = true;
      }
      console.log(elem);

      this.inputValue = key;
      const el = find(this.computedOptionsList, (obj) => obj.key === key);
      if (el === undefined) this.inputValueStr = '';
      else this.inputValueStr = el.text;

      this.isOpen = false;
      this.$emit('changeSelect', key);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "../assets/sass/utils/variables";

  .select{
    position: relative;
    height: 40px;
    background: $color-white;
    cursor: pointer;
    font: 14px $font-global;
    color: $color-outer-space;
    flex-grow: 1;
    border-radius: 3px;
    transition: background-color .2s ease-in;
    user-select: none;

    &_175 {
      width: 175px;
      max-width: 175px;
      input {
        width: 175px;
      }
    }

    &_174 {
      width: 174px;
      max-width: 174px;

      @media (max-width: 1000px) {
        width: 364px;
        max-width: 364px;
      }

      @media (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }

      input {
        width: 174px;

        @media (max-width: 1000px) {
          width: 364px;
        }

        @media (max-width: 767px) {
          width: 100%;
        }

      }
    }

    &-impact {

      .select__input {
        font-size: 18px;
        color: $color-shark;
        font-family: $font-global, sans-serif;
      }
      &:before {
        display: none;
      }

     .select__drop-list {
       text-align: left;
     }

      &:after {
        content: '\E804';
        font-family: $icon-font;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $color-moody-blue;
        font-size: 12px;
      }
    }

    &_h-32 {
      height: 32px;
    }

    &_220 {
      margin-left: 0;
      width: 220px;
      max-width: 100%;
    }

    &_icon {
      .select__input {
        padding-left: 35px;
      }
    }

    &__icon {
      position: absolute;
      top: 10px;
      left: 2px;
      font-size: 25px;
      color: $color-gray-chateau-third;
    }

    &_mass-action {
      margin-left: 12px;
      width: 300px;
    }

    &_sort {
      width: 170px;
      min-width: 170px;
      margin-left: 8px;
    }

    &_our-album {
      margin-left: 22px;
      width: 158px;
    }

    &_institution, &_campuses {
      margin-left: 15px;
      width: 150px;
    }

    &_website {
      margin-left: 0;
      width: 160px;
    }

    &_mm {
      min-width: 92px;
      max-width: 92px;
    }

    &_yyyy {
      min-width: 108px;
      max-width: 108px;
    }

    &_small-width {
      min-width: 180px;
      max-width: 180px;
    }

    &_md-width {
      min-width: 190px;
      max-width: 190px;
    }

    &_xs-width {
      min-width: 150px;
      max-width: 150px;
    }

    &_xxs-width {
      min-width: 85px;
      max-width: 85px;
    }

    &_230 {
      min-width: 230px;
      max-width: 230px;
    }

    &_240 {
      min-width: 240px;
      max-width: 240px;
    }

    &_364 {
      min-width: 364px;
      max-width: 364px;

      @media (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
      }

    }

    &_383 {
      min-width: 383px;
      max-width: 383px;
    }

    &_382 {
      min-width: 382px;
      max-width: 382px;
    }

    &_137 {
      min-width: 137px;
      max-width: 137px;

      @media (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
      }

    }

    &_117 {
      min-width: 117px;
      max-width: 117px;
    }

    &_chart-change-view {
      min-width: 51px;
      max-width: 51px;
      font-size: 12px;

      &:before {
        right: 8px;
      }

      .select__input {
        padding-left: 7px;
        padding-right: 17px;
      }

      .select__check {
        right: 2px;
        margin-top: -2px;
        font-size: 9px;
      }

      .select__drop-link {
        padding: 9px 19px 8px 7px;
      }

    }

    &_lg-height {
      height: 50px;
    }

    &_small{
      height: 32px;

      .select__input {
        padding-left: 8px;
      }

      .select__drop-link {
        padding-left: 8px;
      }

    }

    &_xs-height {
      height: 24px;

      .select__input {
        padding-top: 2px;
      }

    }

    &_disabled{
      cursor: default;
      background-color: $color-seashell;
      pointer-events: none;

      .select__input {
        cursor: default;
      }

      &:before{
        border-color: rgba($color-black, 0.3) transparent transparent transparent !important;
      }
    }

    &_widget-ticket {
      height: calc(100% - 2px);
      margin: 1px;
      border: none;

      .select__input {
        border: none!important;
        border-radius: 0;
      }
    }

    &_filter {
      margin-left: 0 !important;
      .select__drop {
        max-height: none;
      }
    }

    &:before{
      content: '';
      position: absolute;
      z-index: 0;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 2px;
      border: 4px solid;
      border-color: $color-gray-chateau transparent transparent transparent;
      transition: border-color .3s
    }

    &:hover, &_open{

      &:before{
        z-index: 6;
      }
    }
    &_checked{
      .select__input{
        border-color: $color-moody-blue;
      }
    }
    &:hover{
      &:before{
        border-color: $color-moody-blue transparent transparent transparent;
      }
      .select__input {
        border-color: $color-moody-blue;
      }
    }
    &_open {

      background: $color-white;
      z-index: 11;

      .select__input {
        border-color: $color-mercury!important;
      }

      &:before {
        border-color: $color-moody-blue transparent transparent transparent!important;
      }

      .select__drop {
        display: block;
      }
    }

    &__input{
      width: 100%;
      height: 100%;
      border: 1px solid $color-alto;
      border-radius: 3px;
      background-color: transparent;
      padding: 0 24px 0 12px;
      cursor: pointer;
      font-family: $font-global, sans-serif;
      font-size: inherit;
      transition: border-color .2s ease-in;
      user-select: none;

      &::-webkit-input-placeholder {
        font-family: $font-global;
        text-shadow: none !important;
      }
      &::-moz-placeholder {
        font-family: $font-global;
        text-shadow: none !important;
      }
      &:-moz-placeholder {
        font-family: $font-global;
        text-shadow: none !important;
      }
      &:-ms-input-placeholder {
        font-family: $font-global;
        text-shadow: none !important;
      }

      &:focus {
        border-color: $color-moody-blue;
        color: transparent!important;
        text-shadow: 0 0 0 $color-outer-space;

        &::-webkit-input-placeholder,
        &::-moz-placeholder,
        &:-moz-placeholder,
        &:-ms-input-placeholder {
          opacity: 0;
        }
      }
    }

    &__drop {
      display: none;
      position: absolute!important;
      top: 100%;
      left: 0;
      z-index: 5;
      width: 100%;
      height: auto !important;
      max-height: 195px!important;
      margin-top: 2px;
      overflow: hidden;
      border-radius: 3px;
      padding: 4px 0;
      border: 1px solid #e5e5e5;
      background: $color-white;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      color: $color-outer-space;

      &_top {
        bottom: 100%!important;
        margin-bottom: 2px!important;
        top: auto!important;
      }

      &_bottom {
        bottom: 0!important;
        top: auto!important;
      }

      &-link {
        display: block;
        //line-height: 31px;
        padding: 9px 37px 8px 11px;
        text-decoration: none;
        color: $color-outer-space!important;
        transition: color .15s ease-in;

        &_checked {
          position: relative;
          background-color: transparent;
          color: $color-moody-blue!important;
        }

        &_hover {
          background: $color-seashell;
        }
      }

      &-list > div {
        position: relative;

        &:hover {
          a {
            background: $color-seashell;
          }
        }
      }
    }

    &__check {
      position: absolute;
      display: none;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      font-size: 10px;
      color: $color-moody-blue;

      &_checked {
        display: block;
      }
    }

    &_solution {
      background: transparent;
      width: 130px;

      .select__drop {
        background: $color-white;
        mix-blend-mode: normal;
        opacity: .85;
        box-shadow: 0 8px 16px rgba(48, 49, 51, .2);
        border-radius: 4px;
        max-height: 128px !important;
        margin-top: 0;
        padding: 3px 0;
        border: none;
        left: -10px;
        width: 133px;

        &-list {
          padding: 1px 5px;

          .icon-confirm {
            display: none;
          }

          div {
            margin-bottom: 0;

            &:hover {
              opacity: 1;
            }

            &:hover {
              a {
                background: rgba(113, 111, 206, .1);
                color: $color-moody-blue !important;
              }

            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        &-link {
          font-size: 14px;
          font-family: $font-global-medium, sans-serif;
          padding-top: 12px;
          padding-bottom: 12px;

          &_checked {
            background: rgba(113, 111, 206, .1);
            color: $color-moody-blue !important;
            pointer-events: none !important;
          }
        }
      }

      &:before {
        display: none;
      }

      .select {
        &__input {
          border: none;
          font-family: $font-global-bold, sans-serif;
          font-size: 16px;
          line-height: 24px;
          text-transform: uppercase;
          color: $color-white;
          transition: color .15s ease-in;

          &:hover {
            &::placeholder {
              color: $color-moody-blue;
            }
          }

          &::placeholder {
            border: none;
            font-family: $font-global-bold, sans-serif;
            font-size: 16px;
            line-height: 24px;
            text-transform: uppercase;
            color: $color-white;
            transition: color .15s ease-in;
          }

          &:focus {
            color: $color-moody-blue !important;
            text-shadow: none;
          }

          &:hover {
            color: $color-moody-blue;
          }
        }
      }

      @media (max-width: 767px) {
        background: $color-white;
        width: 168px;

        .select__input {
          text-transform: none;
          color: $color-shark;

          &::placeholder {
            color: $color-shark;
            text-transform: none;
          }
        }

        &.select_open {
          &:after {
            color: $color-black;
          }
        }

        .select__check {
          display: none;
        }

        .select__drop {
          left: 258px;
          top: 36px;
          width: 168px;
          opacity: 1;

          &-link {
            text-align: left;
            font-family: $font-global, sans-serif;
            font-size: 16px;
            color: $color-shark;
            padding-top: 11px;
            padding-bottom: 10px;
          }
        }

        &:after {
          content: '\E804';
          font-family: $icon-font;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%) rotate(-90deg);
          color: $color-alto;
          font-size: 12px;
        }
      }
    }
  }

  .select-row {
    display: flex;

    .select + .select {
      margin-left: 10px;
    }

    .select + .text-field {
      margin-left: 10px;
    }
  }

  .select_not-started {

    &:before {
      border-color: $color-white transparent transparent transparent !important;
    }

    .select__input {
      background: $color-mandy;
      border: transparent;
      color: $color-white;
      transition: background-color .15s ease-in;

      &:focus {
        text-shadow: 0 0 0 $color-white;
      }
    }
  }

  .select_in-progress {

    &:before {
      border-color: $color-white transparent transparent transparent !important;
    }

    .select__input {
      background: $color-buttercup;
      border: transparent;
      color: $color-white;
      transition: background-color .15s ease-in;

      &:focus {
        text-shadow: 0 0 0 $color-white;
      }
    }
  }

  .select_complete {

    &:before {
      border-color: $color-white transparent transparent transparent !important;
    }

    .select__input {
      background: $color-elm;
      border: transparent;
      color: $color-white;
      transition: background-color .15s ease-in;

      &:focus {
        text-shadow: 0 0 0 $color-white;
      }
    }
  }

  .select_in-progress {
    background: $color-buttercup;
  }

  .select-header:hover .select__input {
    border-color: $color-nevada !important;
  }

  .select-header.select_open {
    .select__input {
      border-color: $color-nevada !important;
    }
  }

  .select-header {
    min-width: 215px;
    max-width: 215px;
    background: transparent;
    color: $color-nevada;

    input {
      border-color: $color-nevada;

      &:focus {
        color: $color-nevada !important;
      }
    }

    .select__drop {
      background: $color-outer-space;
      border-color: $color-nevada;

      &-link {
        color: $color-nevada !important;
      }
    }
  }

</style>
